import React, { Component } from 'react'
import { Link } from 'gatsby'
import './add.css'
import Layout from '../../components/layout'
import styled, { keyframes } from 'styled-components'
import { addEvent } from '../../services/parse-bridge'
import SEO from '../../components/seo'

//NPM
import Loader from 'react-loader-spinner'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class AddPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      eventName: '',
      eventLocation: '',
      eventPrice: '',
      eventDate: '',
      eventFlyer: undefined,
      eventDetails: '',
      uploading: false,
      uploaded: false,
      startDate: new Date()
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange = date => {
    this.setState({
      startDate: date
    });
  };

  handleSubmit(event) {
    const {
      eventName,
      eventLocation,
      eventPrice,
      eventDate,
      eventFlyer,
      eventDetails,
    } = this.state
    this.setState({ uploading: true })
    event.preventDefault()
    addEvent(
      eventName,
      eventLocation,
      eventPrice,
      eventDate,
      eventFlyer,
      eventDetails
    ).then(val => {
      this.setState({ uploading: false })
      if (val === true) {
        alert('Upload Successful!')
        this.setState({ uploaded: true })
        //Tell them we need to vet the event and contact them.
      } else {
        alert('Something went wrong with your uplaod. Try again')
      }
    })
  }

  createFlyer = e => {
    //A file was selected
    console.log(e.target.files[0])
    if (e.target.files[0]) {
      //Create the parse file
      this.setState({ fileSelected: true, eventFlyer: e.target.files[0] })
      //We can handle the upload here or on hitting the upload button
    } else {
      console.log('No file selected')
      this.setState({ fileSelected: false })
    }
  }

  updateInput = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  render() {
    const startDate = new Date()
    return (
      <Layout>
        <SEO
          title="Add your event"
          description="Add your own event to WhereDPump. Once added, it will be available on both our web and mobile apps for the world to see."
        />
        {this.state.uploaded ? (
          <SuccessUploadContainer>
            <Title>Upload Successful!</Title>
            <SuccessCopy>
              Your event was submitted successfully. Once reviewed by our team,
              it will be available in the app.
            </SuccessCopy>
            <Link to="/">Return Home</Link>
          </SuccessUploadContainer>
        ) : (
            <FormContainer>
              <div className="AddPage">
                <div className="addContainer">
                  <div className="addContainerHeader">
                    <h1>Create an event</h1>
                    <p>Please ensure that you fill all fields</p>
                  </div>
                  <div className="addFormContainer">
                    <form noValidate onSubmit={this.handleSubmit}>
                      <div className="event-info">
                        <div>
                          <label htmlFor="eventName">Event name</label>
                          <input
                            type="text"
                            name="eventName"
                            id="eventName"
                            value={this.state.name}
                            onChange={this.updateInput}
                          />
                        </div>
                        <div>
                          <label htmlFor="eventLocation">Location</label>
                          <input
                            type="text"
                            placeholder="Harbour Master"
                            name="eventLocation"
                            id="eventLocation"
                            value={this.state.eventLocation}
                            onChange={this.updateInput}
                          />
                        </div>
                        <div>
                          <label htmlFor="eventPrice">Price</label>
                          <input
                            type="number"
                            placeholder="Enter the lowest price to attend event"
                            name="eventPrice"
                            id="eventPrice"
                            value={this.state.eventPrice}
                            onChange={this.updateInput}
                          />
                        </div>
                        <div>
                          <label htmlFor="eventDate">Date</label>
                          <input
                            type="datetime-local"
                            name="eventDate"
                            id="eventDate"
                            value={this.state.eventDate}
                            onChange={this.updateInput}
                          />
                        </div>
                        <div>
                          <label htmlFor="eventFlyer">Flyer</label>
                          <input
                            type="file"
                            name="eventFlyer"
                            id="eventFlyer"
                            onChange={this.createFlyer}
                          />
                        </div>
                        <div>
                          <label htmlFor="eventFlyer">Details</label>
                          <textarea
                            rows="10"
                            id="eventDetails"
                            name="eventDetails"
                            value={this.state.eventDetails}
                            onChange={this.updateInput}
                          />
                        </div>
                        <div
                          className="ticketStats"
                          style={{ marginBottom: '15px', textAlign: 'center' }}
                        >
                          {this.state.uploading ? (
                            <Spinner>
                              <Loader
                                type="Oval"
                                color="#Fc444e"
                                height="50"
                                width="50"
                              />
                            </Spinner>
                          ) : (
                              <SubmitButton type="submit">Submit</SubmitButton>
                            )}
                        </div>
                      </div>
                      {/* <button type="submit">Create Tickets!</button> */}
                    </form>
                  </div>
                </div>
              </div>
              <TipsContainer>
                <Title>Best practices</Title>
                <Copy>
                  People visit our platform everyday to find their next pump.
                  We've gathered a few tips for you to ensure that your events
                  stands out and you attract as many patrons as possible.
              </Copy>
                <List>
                  <ListItem>
                    Use a high quality, relevant graphic. 1080px x 1080px is
                    recommended.
                </ListItem>
                  <ListItem>
                    Include as many details about your event as possible. Missing
                    details such as starting price can deter patrons.
                </ListItem>
                  <ListItem>
                    Keep your event description fun but informative!
                </ListItem>
                </List>
              </TipsContainer>
            </FormContainer>
          )}
      </Layout>
    )
  }
}
const Spinner = styled.div`
  text-align: center;
  margin: 20px 0;
`
const TipsContainer = styled.div``
const Title = styled.h1`
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 20px;
`
const Copy = styled.p`
  font-size: 14px;
  line-height: 21px;
`
const List = styled.ul``
const ListItem = styled.li`
  font-size: 14px;
  margin-bottom: 0px;
`
const SubmitButton = styled.button`
  border: 2px solid #fc444e;
  background: #fc444e;
  border-radius: 20px;
  padding: 10px 20px;
  min-width: 140px;
  font-family: 'Avenir-Heavy';
  color: white;
`
const Fade = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`
const FormContainer = styled.div``
const SuccessUploadContainer = styled.div`
  display: flex;
  opacity: 0;
  animation: ${Fade} 0.4s ease-in-out 0.1s forwards;
  margin-top: -10vh;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  padding: 0 10%;
`
const SuccessCopy = styled.p`
  font-size: 16px;
  color: #585858;
`
const EmailContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`
const EmailInput = styled.input`
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 10px;
  flex: 2;
  text-align: center;
`
const EmailSubmit = styled.button`
  flex: 1;
  color: #fc444e;
  background: none;
  border: none;
`

export default AddPage
